import React, {useState} from 'react';
import { useApolloClient, useMutation} from '@apollo/react-hooks';
import { navigate } from '@reach/router';

import { Button } from '@axeedge/go-teacher-components';
import { BOOK_STATUS } from '../../../services/utils/constants';

import styles from '../Task.module.scss';
import ButtonLink from '../../../components/ButtonLink';
import { START_WRITING_ACTIVITY_MUTATION, GET_TASK_QUERY } from '../services/graphql';
import { updateChapter } from '../../Book/services/utils';
import * as Icon from 'react-feather';
import moment from 'moment';

const Sidebar = ({ task, classBookId }) => {
    const client = useApolloClient();

    Date.prototype.toDateInputValue = (function() {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    });

    const timeOptions = [
        {
            id: 1,
            time: '00:00:00'
        },
        {
            id: 2,
            time: '01:00:00'
        },
        {
            id: 3,
            time: '02:00:00'
        },
        {
            id: 4,
            time: '03:00:00'
        },
        {
            id: 5,
            time: '04:00:00'
        },
        {
            id: 6,
            time: '05:00:00'
        },
        {
            id: 7,
            time: '06:00:00'
        },
        {
            id: 8,
            time: '07:00:00'
        },
        {
            id: 9,
            time: '08:00:00'
        },
        {
            id: 10,
            time: '09:00:00'
        },
        {
            id: 11,
            time: '10:00:00',
        },
        {
            id: 12,
            time: '11:00:00',
        },
        {
            id: 13,
            time: '12:00:00'
        },
        {
            id: 14,
            time: '13:00:00'
        },
        {
            id: 15,
            time: '14:00:00'
        },
        {
            id: 16,
            time: '15:00:00'
        },
        {
            id: 17,
            time: '16:00:00'
        },
        {
            id: 18,
            time: '17:00:00'
        },
        {
            id: 19,
            time: '18:00:00'
        },
        {
            id: 20,
            time: '19:00:00'
        },
        {
            id: 21,
            time: '20:00:00'
        },
        {
            id: 22,
            time: '21:00:00'
        },
        {
            id: 23,
            time: '22:00:00'
        },
        {
            id: 24,
            time: '23:00:00'
        },
    ]

    const getDate = (date) => {
        let newDate = moment(date, 'YYYY-MM-DD HH:mm:ss')
        return newDate.format('YYYY-MM-DD')
    }

    const getTime = (time) => {
        let newDate = moment(time, 'YYYY-MM-DD HH:mm:ss')
        return newDate.format('HH:mm:ss')
    }

    const [scheduleErrors, setScheduleErrors] = useState(null);
    const [taskData] = useState(task ? task : null)
    const [hideSchedule, setHideSchedule]= useState((taskData && taskData.startsAt) && true)

    const [formData, setFormData] = useState({
        startDate: (task && task.startsAt) ? getDate(task.startsAt) : new Date().toDateInputValue(),
        startTime: (task && task.startsAt) ? getTime(task.startsAt) : '09:00:00',
        endDate: (task && task.startsAt) ? getDate(task.endsAt) : new Date().toDateInputValue(),
        endTime: (task && task.startsAt) ? getTime(task.endsAt) : '17:00:00',
    })


    const handleStartDate = (newDate) => {
        // check if start date is greater than end date, update end date to be equal to the start date other wise we just set the date
        if (newDate >= formData.endDate) {
            let endTime = moment(formData.endTime, 'HH:mm:ss');
            // if the start time and end time match on the same day then we need to increase the end time by one hour othwreise we just set the dates.
            if (formData.startTime === formData.endTime) {
                if (formData.endTime === '23:00:00') {
                    endTime = '00:00:00';
                    let endDay = moment(formData.endDate, 'YYYY-MM-DD');
                    endDay = endDay.add(1, 'days');
                    setFormData({...formData, startDate: newDate, endDate: endDay.format('YYYY-MM-DD'), endTime: endTime})
                } else {
                    endTime = endTime.add(1, 'hours')
                    setFormData({...formData, startDate: newDate, endDate: newDate, endTime: endTime.format('HH:mm:ss')})
                }
            } else {
                setFormData({...formData, startDate: newDate, endDate: newDate})
            }
        } else {
            setFormData({...formData, startDate: newDate})
        }
    }

    const handleStartTime = (newTime) => {
        //if the dates are the same and the time is the same then we need to increase the end time by one hour
        if (formData.startDate >= formData.endDate && newTime >= formData.endTime) {
            let endTime = moment(formData.endTime, 'HH:mm:ss');
            if (formData.endTime === '23:00:00') {
                endTime = '00:00:00';
                let endDay = moment(formData.endDate, 'YYYY-MM-DD');
                endDay = endDay.add(1, 'days');
                setFormData({...formData, startTime: newTime, endDate: endDay.format('YYYY-MM-DD'), endTime: endTime})   
            } else {
                endTime = endTime.add(1, 'hours')
                setFormData({...formData, startTime: newTime, endTime: endTime.format('HH:mm:ss')})
            }
        } else {
            setFormData({...formData, startTime: newTime})
        }
    }

    const handleSchedule = (e) => {
        e.preventDefault();
        let startTime = moment(formData.startDate + ' ' + formData.startTime, 'YYYY-MM-DD HH:mm:ss');
        let endTime = moment(formData.endDate + ' ' + formData.endTime, 'YYYY-MM-DD HH:mm:ss');
        let currentTime = moment(new Date());
        if (startTime >= endTime || !startTime || !endTime) {
            setScheduleErrors("Please select an End Date greater than the Start Date.");
            return
        }
        if (endTime.format('YYYY-MM-DD HH:mm:ss') < currentTime.format('YYYY-MM-DD HH:mm:ss')) {
            setScheduleErrors("Please select an end date greater than the current time.");
            return
        }
        setSchedule({
            variables: {
                classBookId: task && task.id,
                startsAt: startTime,
                endsAt: endTime,
        }})
    }

    const [setSchedule, {loading: scheduleLoading}] = useMutation(START_WRITING_ACTIVITY_MUTATION, {
        onCompleted: ({ startWritingActivity }) => {
            /*updateChapter(startWritingActivity, client);*/
            setHideSchedule(true)
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_TASK_QUERY, variables: { classBookId } }]
    })


    const [startActivity, { loading }] = useMutation(START_WRITING_ACTIVITY_MUTATION, {
        onCompleted: ({ startWritingActivity }) => {
            updateChapter(startWritingActivity, client);
            navigate(`/book/${task && task.id}`);
        }
    })

    const notStarted = !task || task.status < BOOK_STATUS.started;

    const startDate = (task && task.startsAt) && moment(task.startsAt, 'YYYY-MM-DD HH:mm:ss');
    const endDate = (task && task.startsAt) && moment(task.endsAt, 'YYYY-MM-DD HH:mm:ss');
    return (
        <div className={styles.sidebar}>
            <div className={styles.sidebarContent}>
                <h4 className={styles.sidebarActionsTitle}>{notStarted ? 'Ready?' : 'All good?'}</h4>
                {
                    notStarted ?
                    (
                        <>
                        <Button onClick={() => startActivity({
                            variables: {
                                classBookId: task && task.id
                            }})} disabled={!task || !task.id || loading || scheduleLoading}>
                            {loading ? 'Starting...' : 'Start Writing'}
                        </Button>
                        {hideSchedule ?
                        <div className={styles.scheduleForm}>
                            <h4 className={styles.sidebarActionsTitle}>Current Schedule</h4>
                            <h5 className="heavy">Writing Starts:</h5>
                            <div className={styles.labelSubSet}>
                                {(task && task.startsAt) &&
                                    <>
                                    <p>{startDate.format('DD-MM-YY')}</p>
                                    <Icon.AtSign size="18" className={styles.startedAtSign} />
                                    <p>{startDate.format('h a')}</p>
                                    </>
                                }
                            </div>
                            <h5 className="heavy u-m-top-2">Writing Ends:</h5>
                            <div className={styles.labelSubSet}>
                                {(task && task.startsAt) &&
                                    <>
                                    <p>{endDate.format('DD-MM-YY')}</p>
                                    <Icon.AtSign size="18" className={styles.startedAtSign} />
                                    <p>{endDate.format('h a')}</p>
                                    </>
                                }
                            </div>  
                            <p onClick={() => {setHideSchedule(false)}} className={styles.editSchedule}>Edit Schedule</p>
                        </div> :
                        
                        <form onSubmit={(e) => handleSchedule(e)} className={styles.scheduleForm}> 
                            <h4 className={styles.sidebarActionsTitle}>Or Set Schedule</h4>
                            <h5 className="heavy">Writing Starts:</h5>
                            <div className={styles.labelSet}>
                                <input placeholder="5/10/25" min={new Date().toDateInputValue()} type="date" onChange={(e) => handleStartDate(e.currentTarget.value)} value={formData.startDate} className={styles.styledDate} />
                                <div className={styles.labelSubSet}>
                                    <Icon.AtSign size="18" />
                                    <select value={formData.startTime} onChange={(e) => handleStartTime(e.currentTarget.value)} className={styles.timePicker}>
                                        {timeOptions.map(option => {
                                        var displayTime = moment(option.time, 'HH:mm:ss');
                                        return (
                                            <option
                                                key={`time_${option.id}`}
                                                value={option.time}
                                            >
                                            {displayTime.format('h a')}
                                            </option>
                                        )
                                    })}
                                    </select>
                                </div>
                            </div>
                            <h5 className="heavy">Writing Ends:</h5>
                            <div className={styles.labelSet}>
                                <input placeholder="5/10/25" type="date" min={formData.startDate} onChange={(e) => setFormData({...formData, endDate: e.currentTarget.value})} value={formData.endDate} className={styles.styledDate} />
                                <div className={styles.labelSubSet}>
                                    <Icon.AtSign size="18" />
                                    <select value={formData.endTime} onChange={(e) => setFormData({...formData, endTime: e.currentTarget.value})} className={styles.timePicker}>
                                        {timeOptions.map(option => {
                                        var displayTime = moment(option.time, 'HH:mm:ss');
                                        if (formData.startDate === formData.endDate && option.time <= formData.startTime) {return null}
                                        return (
                                            <option
                                                key={`time_${option.id}`}
                                                value={option.time}
                                            >
                                            {displayTime.format('h a')}
                                            </option>
                                        )
                                    })}
                                    </select>
                                </div>
                            </div>
                            {scheduleErrors && <p>{scheduleErrors}</p>}
                            <Button type="submit" disabled={!task || !task.id || loading || scheduleLoading}>{(task && task.startsAt) ? 'Update Schedule' : 'Set Schedule'}</Button>
                        </form>
                        }
                        </>
                    ) :
                    <ButtonLink to={`/book/${task.id}`}>Continue</ButtonLink>
                }
                
            </div>
        </div>
    );
}

export default Sidebar;