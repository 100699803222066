import { gql } from "apollo-boost";

export const GET_DIGITAL_CLASS_BOOK = gql`
query classBook($classBookId: ID!) {
    classBook(classBookId: $classBookId) {
        id
        status
        classBookPdf
        studentsPdfs {
            id
            url
        }
        bookTemplate {
            id
            name
            coverUrl
            ispStarter
        }
        studentsClass {
            id
            name
            students {
                id
                name
            }
            school {
                id
                name
                country
            }
        }
    }
}`
;