import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';
import { getUrl, APPS } from '@axeedge/go-shared-utils';
import { Loader } from '@axeedge/go-teacher-components';

import { AuthContext } from '../../../services/auth/AuthProvider';
import { GET_USER } from '../services/graphql';

const UserQueryHolder = () => {
    const { data, error, loading } = useQuery(GET_USER);
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    
    useEffect(() => {
        if (data && data.currentTeacher && data.currentTeacher.id && !currentUser && ls('go_teacher_user') !== 'undefined') {
            setCurrentUser(data.currentTeacher);
        }
    }, [currentUser, data, setCurrentUser])

    if (data && data.currentTeacher && data.currentTeacher.id && !data.currentTeacher.school.canAccessBoomWriteApp) {
        window.location = `${getUrl(APPS.go_portal_teacher)}`;
    }


    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    return null;
}

export default UserQueryHolder;

