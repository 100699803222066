import JSZip from 'jszip';
import axios from 'axios';

export const onDownloadSingleFile = (pdfUrl, name) => {
    axios({
        method: 'get',
        url: pdfUrl,
        responseType: 'blob'
    }).then((response => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/pdf' });
        let link = document.createElement('a');
        link.href = downloadUrl;
        const fileName = `${name}-${pdfUrl.split('/').pop()}`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(downloadUrl);

    })).catch((error) => {
        console.error('Error downloading the PDF:', error);
    });
    
}

export const onDownloadFiles = async (studentsArr, setProgress, className) => {

    const batchSize = 5;
    const zip = new JSZip();
    const folder = zip.folder("books_pdf");

    let completedDownloads = 0;

    const processFile = async (student, globalIndex) => {
        try {
            const response = await axios.get(student.url, {
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    // https://github.com/axios/axios/issues/1591#issuecomment-2278597055
                    // might not use progressEvent
                    // console.log('progressEvent', progressEvent);
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(`Downloading file ${globalIndex + 1} of ${studentsArr.length}: ${percentCompleted}%`);
                }
            });
            const contentDisposition = response.headers['content-disposition'];
            let filename = `${student.name.replace(/ /g, "_")}-${student.url?.split('/').pop()}`;
            if (contentDisposition) {
                const matches = /filename="?(.+)"?/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1];
                }
            }
            folder.file(filename, response.data);
            completedDownloads += 1;
            setProgress(`Downloaded ${completedDownloads} of ${studentsArr.length} files.`);
        } catch (error) {
            console.error(`Failed to download file from ${student?.url}:`, error);
        }
    }

    // for (let i = 0; i < urls.length; i += batchSize) {
    //     const batch = urls.slice(i, i + batchSize);
    //     await Promise.all(batch.map(async (url, index) => processFile(url, i + index)));
    // }

    for (let i = 0; i < studentsArr.length; i += batchSize) {
        const batch = studentsArr.slice(i, i + batchSize);
        await Promise.all(batch.map(async (student, index) => processFile(student, i + index)));
    }

    setProgress('Creating zip file...');

    zip.generateAsync({ type: "blob" }).then((content) => {
        const downloadUrl = window.URL.createObjectURL(content);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${className}_books.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
        setProgress('');
    });
}


