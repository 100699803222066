import { gql } from "apollo-boost";
import { classBookQuery } from "../../../services/constants";

export const ISP_TEMPLATES = gql`
query filteredBookTemplates($ispStarters: Boolean) {
    filteredBookTemplates(ispStarters: $ispStarters) {
        id
        name
        ispStarter
        editableTitle
        isSingleChapter
        resourcesUrl
        coverUrl
        chapters {
            id
            title
            content
            description
            orderid
            guidance
        }
        genre {
            id
            name
        }
    }
}
`;

export const ASSIGN_BOOK_TEMPLATE_TO_CLASS = gql`
mutation assignBookTemplateToClass($studentsClassId: ID!, $bookTemplateId: ID!, $numberOfChapters: Int!) {
    assignBookTemplateToClass(studentsClassId: $studentsClassId, bookTemplateId: $bookTemplateId, numberOfChapters: $numberOfChapters) {
        errors
        classBook {
            ${classBookQuery}
        }
    }
}
`;