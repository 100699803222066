import React, { useState, Fragment, useEffect } from 'react';
import { useLocation } from 'react-use';
import { parse } from 'query-string';

import Output from '../../../../../../components/EditorJsOutput';
import StudentsEntriesList from '../../../StudentsEntriesList';
import TeacherEdit from '../../../StudentsEntriesList/components/TeacherEdit';

import Tabs from '../../../../../../components/Tabs';
import Tab from '../../../../../../components/Tab';
import PrintableEntries from './components/PrintableEntries';
import VocabModule from '../VocabModule';

const TABS = {
    showWinner: 0,
    editWinner: 1,
    showAll: 2
}


const Completed = ({ chapter, book }) => {
    const [selectedTab, setSelectedTab] = useState(chapter.classBook.castingEnabled ? TABS.showWinner : TABS.showAll);
    const [winner, setWinner] = useState(chapter.studentEntries.find(entry => { return entry.winner }));

    useEffect(() => {
        setWinner(chapter.studentEntries.find(entry => { return entry.winner }))
    }, [chapter, setWinner])

    useEffect(() => {
        setSelectedTab(chapter.classBook.castingEnabled ? TABS.showWinner : TABS.showAll)
    }, [chapter.orderid, setSelectedTab, chapter])

    const location = useLocation();

    if (parse(location.search).printStudentsChapter) {
        return <PrintableEntries chapter={chapter} />
    }

    const escapeVocab = vocab => vocab.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    
    const highlightVocab = (text, vocab) => {
        let newText = text;
        vocab.map(v => {
            let exp = RegExp('\\b(' + escapeVocab(v) + ')\\b', 'gi');
            newText = newText.replace(exp, `<span class=\"tagvocab\">${v}</span>`)

        })
        return newText;
    }

    const highlightEntry = (entryData) => {
        const vocab = chapter.vocabularyWords;
        entryData.blocks.forEach(block => {
            if (block.data.text && block.data.text.length !== 0) {
                block.data.text = highlightVocab(block.data.text, vocab)
            }
        });
        return entryData
    }


    return (
        <Fragment>
            <h1 className='u-m-base-3'>Completed</h1>
            {
                chapter.classBook.castingEnabled && (
                    <Tabs className='u-m-base-3'>
                        <Tab onClick={() => setSelectedTab(TABS.showWinner)} selected={selectedTab === TABS.showWinner}>Winner</Tab>
                        {/* {!book.classPack.isWritingActivity && */}
                            <Tab onClick={() => setSelectedTab(TABS.editWinner)} selected={selectedTab === TABS.editWinner}>Edit</Tab>
                        {/* } */}
                        <Tab onClick={() => setSelectedTab(TABS.showAll)} selected={selectedTab === TABS.showAll}>All Students</Tab>
                    </Tabs>
                )
            }

            {
                chapter.classBook.castingEnabled && selectedTab === TABS.showWinner && (
                    <Fragment>

                        <h4 className='u-m-base-2'>Winner: <span className='heavy'>{winner.author.name}</span></h4>
                        {chapter.vocabularyWords?.length > 0 && <VocabModule entry={JSON.parse(winner.text)} vocab={chapter.vocabularyWords} />}

                        <div className='card-entry u-m-top-2'>
                            {chapter.vocabularyWords?.length > 0 ?
                                <Output data={highlightEntry(JSON.parse(winner.text))} /> :
                                <Output data={JSON.parse(winner.text)} />
                            }
                        </div>
                    </Fragment>
                )
            }
            {
                selectedTab === TABS.editWinner && <TeacherEdit entry={winner} bookStatus={chapter.classBook.status} />
            }
            {
                selectedTab === TABS.showAll && (
                    <StudentsEntriesList
                        writing={true}
                        voted={chapter.classBook.castingEnabled}
                        votes={chapter.classBook.castingEnabled}
                        award={true}
                        eligible={chapter.classBook.castingEnabled}
                        visibleInCasting={chapter.classBook.castingEnabled}
                        status={chapter.status}
                        bookStatus={chapter.classBook.status}
                        entries={chapter.studentEntries}
                    />
                )
            }
        </Fragment>
    );
}

export default Completed;
