import React, { useCallback, useState, Fragment, useRef, useEffect } from 'react';
import EditorJs from 'react-editor-js';
import { useMutation } from '@apollo/react-hooks';
import { SET_TEACHER_EDIT_MUTATION, SET_TEACHER_EDIT_WINNER_MUTATION } from '../../../../services/graphql';
import Output from '../../../../../../components/EditorJsOutput';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import styles from './TeacherEdit.module.scss';
import { BOOK_STATUS } from '../../../../../../services/utils/constants';

const TeacherEdit = ({ entry, bookStatus, setShowEdit = false }) => {

    const [formErrors, setFormErrors] = useState([]);
    const editorJsRef = useRef(null);

    useEffect(() => {
        if (editorJsRef.current) {
            editorJsRef.current.isReady.then(() => {
                editorJsRef.current.clear();
                editorJsRef.current.render(JSON.parse(entry.teacherEdit || entry.text))
             });
        }
    }, [entry])

    const [saveEdit, { loading: savingEdit }] = useMutation(SET_TEACHER_EDIT_MUTATION, {
        onCompleted: ({ teacherEditEntry }) => {
            if (teacherEditEntry.errors && teacherEditEntry.errors.length !== 0) {
                setFormErrors(teacherEditEntry.errors);
                return;
            }
        }
    })

    const [saveWinnerEdit, { loading: savingWinnerEdit }] = useMutation(SET_TEACHER_EDIT_WINNER_MUTATION, {
        onCompleted: ({ teacherEditWinnerEntry }) => {
            if (teacherEditWinnerEntry.errors && teacherEditWinnerEntry.errors.length !== 0) {
                setFormErrors(teacherEditWinnerEntry.errors);
                return;
            }
        }
    })

    const onSaveEdit = useCallback(async () => {
        const savedData = await editorJsRef.current.save();
        if (savedData.blocks.length !== 0) {
            if (entry.winner) {
                saveWinnerEdit({
                    variables: {
                        bookChapterStudentEntryId: entry.id,
                        teacherEdit: JSON.stringify(savedData)
                    }
                })
            } else {
                saveEdit({
                    variables: {
                        bookChapterStudentEntryId: entry.id,
                        teacherEdit: JSON.stringify(savedData)
                    }
                })
            }
        }

    }, [entry, saveEdit, saveWinnerEdit])

    return(
        <Fragment>
            {bookStatus <= BOOK_STATUS.completed ? (
                <Fragment>
                <p className='u-m-base-2'>This is the version of the entry that will appear in the printed book. Use the editor to correct spelling mistakes etc:</p>
                <div className='editor'>
                    <EditorJs
                        data={JSON.parse(entry.teacherEdit || entry.text)}
                        enableReInitialize={false}
                        instanceRef={instance => (editorJsRef.current = instance)}
                        minHeight='0'
                        logLevel='WARN'
                    />
                </div>
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <ul className={styles.saveActions}>
                    <li><Button className='u-m-right-1' disabled={savingEdit} onClick={() => onSaveEdit()} primary>{savingEdit || savingWinnerEdit ? 'Saving...' : 'Save Changes'} </Button></li>
                    <li>
                        <Button
                            outline
                            onClick={() => {
                                editorJsRef.current.render(JSON.parse(entry.teacherEdit || entry.text));
                                setShowEdit && setShowEdit(false);
                            }}
                        >Cancel</Button>
                    </li>
                    {setShowEdit && <li className={styles.saveActionsBack}><Button onClick={() => setShowEdit(false)}>Done</Button></li>}
                </ul>
                </Fragment>
            ) : (
                <Fragment>
                    <p className='u-m-base-2'>This is the version of the entry that will appear in the printed book.</p>
                    <div className='card-entry'>
                        <Output data={JSON.parse(entry.teacherEdit || entry.text)} />
                    </div>
                </Fragment>
            )}

        </Fragment>
    )
}

export default TeacherEdit;
