import React, { useState, useContext } from "react";
import SelectWrite from "../components/SelectWrite";
import { AuthContext } from "../../../services/auth/AuthProvider";
import { itemDateColor } from "../../../services/utils";
import { ArrowLeft, Users } from "react-feather";
import { Link } from "@reach/router";
import styles from '../Writes.module.scss';

const StartWrite = () => {

    const { currentUser: teacher } = useContext(AuthContext)

    const [selectedClass, setSelectedClass] = useState(null);


    if (selectedClass) {
        return <SelectWrite selectedClass={selectedClass} close={() => setSelectedClass(null)} />
    }


    return (
        <>
            <div className='page-header'>
                <div className='page-header__content'>
                    <div className='page-header__content__inner'>
                        <h2 className='page-header__content__title'><Users /> Select a class/group </h2>
                    </div>
                </div>
                <div className='page-header__actions'>
                    <Link className="u-display-flex u-align-center u-text-dark" to='/'><ArrowLeft className="u-m-right-1"/> Back</Link>
                </div>
            </div>
            {teacher.studentsClasses.length === 0 ? <p>No classes assigned yet</p> :
                <div className='row'>
                    {teacher.studentsClasses.map(cls => (
                        <button key={cls.id} onClick={() => setSelectedClass(cls)} className={`${styles.write} col-lg-4 col-md-6`}>
                            <div className='card card-hover'>
                                <div className={styles.writeContent} style={{ background: itemDateColor(cls.createdAt) }}>
                                    <h2 className={styles.writeTitle}>{cls.name}</h2>
                                </div>
                                <div className='card-footer'>
                                    <p className={styles.writeSubTitle}>{cls.description}</p>
                                </div>
                            </div>
                        </button>
                    ))}
                </div>
            }
        </>
    )
}

export default StartWrite;