import React, { useState } from "react";
import { Book, Lock, Users, ArrowLeft, Edit2 } from "react-feather";
import StartIsp from "./StartIsp";
import ImageFadeIn from "react-image-fade-in";
import ispLogo from '../../../images/isp_logo.png';
import styles from '../Writes.module.scss';

const SelectWrite = ({ selectedClass, close }) => {

    const [showIsp, setShowIsp] = useState(false);



    return (
        <>
            <div className='page-header'>
                <div className='page-header__content'>
                    <div className='page-header__content__inner'>
                        <h2 className='page-header__content__title'><Users />{selectedClass.name}</h2>
                    </div>
                </div>
                <div className='page-header__actions'>
                    <button onClick={close} className="btn-reset u-display-flex u-align-center u-text-dark"><ArrowLeft className="u-m-right-1" /> Back</button>
                </div>
            </div>
            {showIsp ? <StartIsp selectedClass={selectedClass} /> :

                <>
                    <div className="row">
                        <div className="col-md-6 u-display-flex">
                            <div className={`${styles.lockedWrites} u-text-center u-relative`}>
                                <Edit2 size={40} />
                                <Lock size={28} className={styles.lockIcon} />
                                <h1 className="u-m-top-2 u-m-base-2">Short write</h1>
                                <p className="u-m-base-2">Pupils will write once in response to a teacher prompt. Once submitted, short writes will be shared with other pupils in the class to suggest edits. </p>
                                <p>Teachers will be able to access the "version history" of each pupil’s writing including the edit suggestions and final outcome.</p>
                            </div>
                        </div>

                        <div className="col-md-6 u-display-flex">
                            <div className={`${styles.lockedWrites} u-text-center u-relative`}>
                                <Book size={40} />
                                <Lock size={28} className={styles.lockIcon} />
                                <h1 className="u-m-top-2 u-m-base-2">Long write</h1>
                                <p className="u-m-base-2">Pupils will write chapters in response to a story starter. After submitting their chapter, Starcasting will begin - pupils will rate each other’s work and the highest rated chapter will be added to the story. </p>
                                <p>Repeat this process until you have your final story - read your book digitally or have hard copies printed. </p>
                            </div>
                        </div>
                    </div>

                    <div onClick={() => setShowIsp(true)} className={styles.isp}>
                        <div><ImageFadeIn width={190} src={ispLogo} alt='' /></div>
                        <div className={styles.ispTitle}>International Schools Partnership writing project</div>
                    </div>
                </>
            }
        </>
    )
}

export default SelectWrite;