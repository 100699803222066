import { APPS } from '@axeedge/go-shared-utils';

export const APP_NAME = APPS.go_write_teacher;

export const BOOK_PRICE = 7.99;

export const ENTRY_HISTORY_TYPE = {
    text: 1,
    status: 2,
    feedback: 3,
    teacherEdit: 4
};

export const studentEntryQuery = `
id
castedCounter
castsCount
eligibleToWin
feedback
hiddenToCasting
stars
status
submittedAt
teacherEdit
text
winner
isActive
bookChapter {
    id
    classBook {
        id
        isSingleChapter
        castingEnabled
        completedAt
        foreword
        status
        classPack {
            id
            isWritingActivity
        }
    }
    vocabularyWords
}
author {
    id
    displayName
    firstName
    lastName
    loginCode
    name
}
`;

export const chapterQuery = `
bookChapter {
    id
    content
    description
    guidance
    orderid
    status
    title
    classBook {
        id
        castingEnabled
        currentChapter
        foreword
        completedAt
        isSingleChapter
        status
        title
        classPack {
            id
            isWritingActivity
            isPromo
        }
    }
    studentEntries(sortBy: "rating") {
        ${studentEntryQuery}
    }
    vocabularyWords
}
`;

export const classBookQuery = `
id
currentChapter
createdAt
foreword
isSingleChapter
completedAt
status
title
castingEnabled
bookCode
endsAt
startsAt
classBookPdf
studentsPdfs {
    id
    url
}
bookTemplate {
    id
    name
    editableTitle
    isSingleChapter
    resourcesUrl
    videoUrl
    coverUrl
    isPromo
    isWriteToRaise
    isBeeTemplate
    ispStarter
    promoOrderId
}
classPack {
    id
    isWritingActivity
    isPromo
    purchasedPack {
        id
    }
    bookTemplateCategory {
        id
        name
        description
    }
}
chapters {
    id
    content
    description
    guidance
    orderid
    status
    title
    classBook {
        id
        currentChapter
        isSingleChapter
        castingEnabled
        completedAt
        foreword
        status
        title
        classPack {
            id
            isWritingActivity
            isPromo
        }
    }
    studentEntries(sortBy: "rating") {
        ${studentEntryQuery}
    }
    vocabularyWords
}
studentsClass {
    id
}
canPrint
printBookOrders {
    id
    orderType
    orderDetails
    status
    sendToDestinationAt
    sendToPrinterAt
    trackingNumber
}
`

export const classPackQuery = `
id
createdAt
isWritingActivity
isPromo
bookTemplate {
    id
    isWriteToRaise
    isBeeTemplate
}
bookTemplateCategory {
    id
    name
    description
}
purchasedPack {
    id
    instances
}
book {
    id
    completedAt
    status
    title
}
`;
