import React, { useState } from "react";
import styles from '../Writes.module.scss';
import { ISP_TEMPLATES, ASSIGN_BOOK_TEMPLATE_TO_CLASS } from "../services/graphql";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import Output from "../../../components/EditorJsOutput";
import FormErrors from "../../../components/FormErrors";

const StartIsp = ({ selectedClass }) => {
    const renderChapterContent = (starter) => {
        try {
            return JSON.parse(starter.content);
        } catch (e) {
            return {
                blocks: [
                    {
                        type: 'paragraph',
                        data: {
                            text: starter.content
                        }
                    }
                ]
            }
        }
    }


    const [selectedStarter, setSelectedStarter] = useState(null);
    const [chapters, setChapters] = useState('');
    const [mutationErrors, setMutationErrors] = useState([]);

    const chaptersMap = Array.from(Array(10).keys(), n => n + 1);

    const { data, loading, error } = useQuery(ISP_TEMPLATES, {
        variables: {
            ispStarters: true
        }
    });

    const [assignBookTemplateToClass, { loading: assigning }] = useMutation(ASSIGN_BOOK_TEMPLATE_TO_CLASS, {
        update: (_, { data }) => {
            if (data?.assignBookTemplateToClass?.errors?.length > 0) {
                setMutationErrors(data.assignBookTemplateToClass.errors);
                return
            }
            if (data?.assignBookTemplateToClass?.classBook?.id) {
                navigate(`/book/${data.assignBookTemplateToClass.classBook.id}`);

            }
        }
    });


    if (loading) return <p>Loading...</p>

    if (error) return <p>{error.message}</p>

    if (data?.filteredBookTemplates) {
        const { filteredBookTemplates } = data;
        return (
            <>
                <div className={styles.selector}>
                    <div className={styles.selectorSite}>
                        <div className="panelLight2 u-m-base-3">
                            {filteredBookTemplates[0]?.ispStarter &&  <p className="u-m-base-2"><span className="label label-primary">ISP</span></p>}
                            <h2 className="u-m-base-2">Select a starter</h2>
                            <ul>
                                {filteredBookTemplates.map(starter => (
                                    <li key={starter.id} className={`${styles.starterItem} ${selectedStarter?.id === starter.id ? styles.starterItemSelected : ''}`} onClick={() => setSelectedStarter(starter)}>{starter.name}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="panelLight2">
                            <h2>Book settings</h2>
                            <p className="u-m-base-2">Number of chapters</p>
                            <select value={chapters} onChange={(e) => setChapters(e.target.value)} className="basic-form__text-select">
                                <option value=''>Please select</option>
                                {chaptersMap.map(el => <option key={`opt-${el}`} value={el}>{el}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className={styles.selectorMain}>
                        <div className="panelLight2">

                            {selectedStarter ?
                                <div>
                                    <div className={`${styles.selectedStarterHead} u-m-base-3`}>
                                        <p className={`${styles.starterItem} ${styles.starterItemSelected} u-m-base-0`}>{selectedStarter.name}</p>
                                        <button
                                            onClick={() => assignBookTemplateToClass({
                                                variables: {
                                                    studentsClassId: selectedClass.id,
                                                    bookTemplateId: selectedStarter.id,
                                                    numberOfChapters: +chapters
                                                }
                                            })}
                                            className="btn btn-primary"
                                            disabled={!chapters || assigning}
                                        >Select</button>
                                    </div>
                                    {mutationErrors && <FormErrors errors={mutationErrors} />}
                                    {selectedStarter.chapters.length > 0 && <Output data={renderChapterContent(selectedStarter.chapters[0])} />}
                                </div>
                                : <p>Select a story start from the left</p>}
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return null;
}

export default StartIsp;