import React, { useState, useContext } from 'react';
import { ArrowLeft, Settings } from 'react-feather';
import { Link, useNavigate } from "@reach/router"
import { Dropdown } from '@axeedge/go-teacher-components';

import { AuthContext } from '../../../../services/auth/AuthProvider';
import PrintableStudentsList from '../../../Class/scenes/Students/components/PrintableStudentsList';
import { BOOK_STATUS } from '../../../../services/utils/constants';

const BookHeader = ({ title, subtitle, classId, showBackBtn, writingActivity, book }) => {
    const [showPrintableList, setShowPrintableList] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    if (showPrintableList) {
        return <PrintableStudentsList classId={classId} school={currentUser.school} exitPrintMode={() => setShowPrintableList(false)} />
    }
    return (
        <div className='page-header no-print'>
            <div className='page-header__content'>
                {
                    (showBackBtn || writingActivity) && (
                        <button className='page-header__back' onClick={() => navigate(`/class/${classId}`)}>
                            <ArrowLeft className='page-header__back__icon' />
                        </button>
                    )
                }
                <div className='page-header__content__inner'>
                    <h1 className='page-header__content__title'>{title}</h1>
                    {subtitle && <p className='page-header__content__subtitle'>{subtitle}</p>}
                </div>
            </div>

            <div className='page-header__actions'>
                <ul>
                    <li>
                        <Dropdown classNames='page-header__dropdown' titleLeft='Resources'>
                            {/* {book && book.status > BOOK_STATUS.new && book.classPack.purchasedPack && !writingActivity && (
                                <li>
                                    <a href={`/book/${book.id}?printParentLetters=true`} target='_blank' rel="noopener noreferrer">Parent Letters</a>
                                </li>
                            )} */}
                            <li><button className='btn-reset' onClick={() => setShowPrintableList(true)}>Printable Pupils List</button></li>
                        </Dropdown>
                    </li>
                    {writingActivity &&
                        <li><Link to={`/task/${writingActivity.id}`}><Settings className='u-m-left-1' /></Link></li>
                    }
                </ul>
            </div>
        </div>
    );
}

export default BookHeader;
