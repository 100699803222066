import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { AuthContext } from '../../../../services/auth/AuthProvider';

import { GET_CLASS_QUERY } from '../../../../services/class/graphql';
import { Loader, Button } from '@axeedge/go-teacher-components';
import _ from 'lodash';
import styles from './BookParentLetters.module.scss';
import cx from 'classnames';
import logo from '../../../../images/logos/boomwriter-full.svg';
import buyQrImg from '../../../../images/qr-write-buy.png';
import buyQrImgBoom from '../../../../images/qr-write-buy-boom.svg';
import IndiaParentLetters from './IndiaParentLetters';

const BookParentLetters = ({ bookData, classId }) => {

    const hostname = window && window.location && window.location.hostname;


    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;

    const { data, error, loading } = useQuery(GET_CLASS_QUERY, {
        variables: {
            id: classId
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.studentsClass) {
        return (
            <div className={styles.studentsPrint}>
                <div className={cx(styles.noPrint, 'hidden-print')}>
                    <div className={styles.noPrintContent}>
                        <Button onClick={() => window.print()}>Print</Button>
                    </div>
                </div>
                { teacher.school?.country === 'IND' ? <IndiaParentLetters bookData={bookData} studentsClass={data.studentsClass} />: (
                    <>
                        {
                            _.sortBy(data.studentsClass.students, ['lastName']).map(student => {
                                return (
                                    <div key={`print-${student.id}`} className={cx(styles.student, styles.printSheet)}>
                                        <div className={styles.studentHead}>
                                            <div>
                                                <h1 className={styles.studentHeadTitle}>Congratulations</h1>
                                                <h2 className={styles.studentName}>{student.name} is published!</h2>
                                            </div>
                                            <img width="100" src={logo} alt='' />
                                        </div>
                                        <div className={styles.studentBook}>
                                            {/* <div>
                                                {bookData.classPack.isPromo ? (
                                                    <p className='u-m-base-2'>Our class have completed a published book. They’ve read, wrote and voted to create an amazing and unique published book.</p>
                                                ) : <p className='u-m-base-2'>Our class have completed a published book. They’ve read, wrote and voted to create an amazing and unique published book.</p>}

                                                {
                                                    bookData.classPack.purchasedPack ?
                                                        <p className='u-m-base-2'>Every child will receive a personalised copy of the published book that they helped to write. Each copy features your child’s name on the cover along with their own written piece.</p> :
                                                        <p className='u-m-base-2'>Every child can purchase a personalised copy of the published book that they helped to write. Each copy features your child’s name on the cover along with their own written piece.</p>
                                                }
                                            </div> */}
                                            {bookData.bookTemplate.coverUrl && (
                                                <img className={styles.studentBookImg} src={bookData.bookTemplate.coverUrl} alt='' />
                                            )}
                                        </div>

                                        {/* {bookData.classPack.purchasedPack ? <h2>Purchase Additional Copies</h2> : <h2>Purchase Your Copies</h2>} */}
                                        <p>You can purchase additional copies for family, friends or as keepsakes for just £7.99/copy plus P&P.</p>

                                        <div className={styles.studentBuy}>
                                            {hostname.includes('boom') ? (
                                                <img className={styles.studentBuyImg} src={buyQrImgBoom} alt='' />
                                            ) : <img className={styles.studentBuyImg} src={buyQrImg} alt='' />}

                                            <div>
                                                <p><b>To purchase, visit:</b></p>
                                                <h2>{hostname.includes('boom') ? 'boomwriter.co.uk/buy' : 'go-write.co.uk/buy'}</h2>
                                                <h2>Book Code: {bookData.bookCode}-{student.storeCode}</h2>
                                            </div>
                                        </div>

                                        <p className='u-m-base-2'>Create your parent account to easily purchase additional books. It's easy and free to join, simply create your account then connect to your child using the details below:</p>
                                        {teacher && <p><b>School code: {teacher.school.schoolCode} </b></p>}
                                        <p><b>Child Login code: {student.loginCode}</b></p>
                                    </div>
                                )
                            })
                        }
                    </>
                )}
            </div>
        )
    }
}

export default BookParentLetters;