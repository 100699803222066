import React, { useState, useEffect} from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Loader } from '@axeedge/go-teacher-components';

import { GET_CLASS_BOOK } from './services/graphql';
import BookHolder from './components/BookHolder';
import { usePageVisibility } from 'react-page-visibility';

const Book = ({ classBookId }) => {

    const [pollInterval, setPollInterval] = useState(0);

    const isVisible = usePageVisibility();

    useEffect(() => {
        const startPolling = () => setPollInterval(20000)
        const stopPolling = () => setPollInterval(0)
        isVisible ? startPolling() : stopPolling()
    }, [isVisible]);

    const { data, error, loading } = useQuery(GET_CLASS_BOOK, {
       // pollInterval: 20000,
       pollInterval,
        variables: {
            classBookId
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data.classBook && data.classBook.id) {
        return (
            <BookHolder bookData={data.classBook} />
        );
    }

    return null;
}

export default Book;
