import React, { useState, useRef } from "react";
import { useQuery } from "@apollo/react-hooks";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import { GET_DIGITAL_CLASS_BOOK } from "../../services/graphql";
import { Download, Eye, X } from "react-feather";
import styles from './DigitalBooks.module.scss';
import bwLogo from '../../../../../../images/logos/boomwriter-full.svg';
import { onDownloadFiles, onDownloadSingleFile } from "./DownloadBooks";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DigitalBooks = ({ book }) => {

    const [showBook, setShowBook] = useState(null);
    const [progress, setProgress] = useState('');

    const [numPages, setNumPages] = useState(0);


    const [selectedStudents, setSelectedStudents] = useState([]);
    const { data, error, loading } = useQuery(GET_DIGITAL_CLASS_BOOK, {
        variables: {
            classBookId: book.id
        }
    });


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const onDocumentLoadError = (error) => {
        console.error("Failed to load PDF:", error);
    };


    const onSelectStudent = e => {
        if (selectedStudents.indexOf(e.target.value) !== -1) {
            setSelectedStudents(selectedStudents.filter(c => c !== e.target.value));
        } else {
            setSelectedStudents([...selectedStudents, e.target.value]);
        }
    }

    const onSelectAllStudents = (e, students) => {
        const selectedIds = students.map(s => s.id);
        e.target.checked ? setSelectedStudents(selectedIds) : setSelectedStudents([])
    }

    const renderBook = (fileUrl) => {
        return (
            <Document
                file={fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
            >
                <HTMLFlipBook
                    width={600}
                    height={600 * 1.4}
                >
                    {/* {Array.from(new Array(Math.ceil(numPages / 2)), (el, index) => {
                        const leftPage = index * 2 + 1;
                        const rightPage = index * 2 + 2;
                        return (
                            <div className={styles.bookPages} key={`spread_${index}`}>
                                {leftPage <= numPages && (
                                    <div className={`${styles.bookPage} ${styles.bookPageLeft}`}>
                                        <ReactPdfPage pageNumber={leftPage} />
                                    </div>
                                )}
                                {rightPage <= numPages && (
                                    <div className={styles.bookPage}>
                                        <ReactPdfPage pageNumber={rightPage} />
                                    </div>
                                )}
                            </div>
                        );
                    })} */}
                    {Array.from(new Array(numPages), (el, index) => (
                        <div key={`page_${index + 1}`}>
                            <ReactPdfPage pageNumber={index + 1} />
                        </div>
                    ))}
                </HTMLFlipBook>
            </Document>
        )
    }


    const getStudentUrl = (student, book) => {
        const studentUrl = book.studentsPdfs.find(el => el.id === student.id)?.url;
        if (studentUrl) {
            return studentUrl;
        }
        return null;
    }

    const onSelectMultipleUrls = (classBook) => {
        const stPdfs = classBook.studentsPdfs || [];
        const studentsUrls = [];
        classBook.studentsClass.students.forEach(student => {
            if (selectedStudents.includes(student.id)) {
                const foundEl = {
                    name: student.name,
                    url: stPdfs.find(el => el.id === student.id)?.url,
                }
                studentsUrls.push(foundEl)
            }
        });
        onDownloadFiles(studentsUrls, setProgress, classBook.studentsClass?.name || 'boomwriter')
    }

    const handleShowBook = book => {
        setShowBook(book);
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <p>Loading...</p>
    }

    if (data?.classBook?.id) {

        const students = data.classBook?.studentsClass?.students || [];
        const { classBook } = data;

        return (
            <>
                <div className={styles.digital}>
                    <div>
                        <div className="panelLight2 u-m-base-3">
                            <h1>{classBook.bookTemplate?.name}</h1>
                            <p className="u-m-base-2">This book contains the winners of all the chapters voted by your class</p>
                            <div className="u-display-flex u-align-items-center">
                                <button className="btn btn-primary u-m-right-2 u-display-flex u-align-items-center" onClick={() => handleShowBook(classBook.classBookPdf)}>Preview <Eye className='u-m-left-1' /></button>
                                <button className="btn btn-primary u-m-right-2 u-display-flex u-align-items-center" onClick={() => onDownloadSingleFile(classBook.classBookPdf, classBook.studentsClass.name)}>Download <Download className='u-m-left-1' /></button>
                            </div>

                        </div>
                        {progress && <p>{progress}</p>}
                        <h2>Personalized books</h2>
                        <ul className="list-group u-m-base-3">
                            <li className="list-item">
                                <input
                                    type="checkbox"
                                    className="u-m-right-1"
                                    checked={selectedStudents.length === students.length}
                                    onChange={(e) => onSelectAllStudents(e, students)}
                                />
                                <p>Select all</p>
                            </li>
                            {students.map(student => (
                                <li key={student.id} className="list-item">
                                    <input
                                        type="checkbox"
                                        className="u-m-right-1"
                                        value={student.id}
                                        checked={selectedStudents.indexOf(student.id) > -1}
                                        onChange={(e) => onSelectStudent(e)}
                                    />
                                    <p>{student.name}</p>
                                    <div className="u-ml-auto u-display-flex u-align-items-center">
                                        <button
                                            className="u-m-right-2"
                                            onClick={() => onDownloadSingleFile(getStudentUrl(student, classBook), student.name)}                                    ><Download size={20} /></button>
                                        <button className=" u-text-primary" onClick={() => setShowBook(getStudentUrl(student, classBook))}><Eye size={20} /></button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <button
                            disabled={selectedStudents.length === 0}
                            className='btn btn-primary u-display-flex u-align-items-center'
                            onClick={() => onSelectMultipleUrls(classBook)}
                        >Download <Download className='u-m-left-1' /></button>
                    </div>
                    <div>
                        <div className="panelLight2">
                            <div className={styles.digitalCover}>
                                <img src={classBook.bookTemplate?.coverUrl} className={styles.digitalCoverImg} />
                                <div className={styles.digitalCoverFooter}>
                                    <div>
                                        <p className="bold u-m-base-2">By {classBook?.studentsClass?.name}</p>
                                        <p className="bold u-m-base-2">{classBook?.studentsClass?.school?.name}</p>

                                        <p className="bold u-m-base-2">{classBook?.studentsClass?.school?.country}</p>
                                    </div>
                                    <div>
                                        <img src={bwLogo} width={200} alt='BW logo' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {showBook && (
                    <div className={styles.bookModal}>
                        <div
                            onClick={(event) => {
                                if (event.target.classList.contains(styles.bookModalBlanker)) {
                                    setShowBook(null);
                                }
                            }}
                            className={styles.bookModalBlanker}>
                            <div className={styles.bookModalContent}>
                                <button onClick={() => setShowBook(null)} className={styles.bookModalClose}><X/></button>
                                <div className={styles.bookModalInner}>
                                    {renderBook(showBook)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

export default DigitalBooks;