export const APPS = {
    go_admin: 1,
    go_write_teacher: 2,
    go_write_pupil: 3,
    go_read_teacher: 4,
    go_read_pupil: 5,
    go_portal_teacher: 6,
    go_portal_pupil: 7,
    go_parents_desktop: 8,
    go_parents_mobile: 9,
    boom_math_teacher: 10,
    boom_math_pupil: 11,
    boom_math_portal_teacher: 12,
    boom_math_portal_pupil: 13,
};

export const APP_TOKEN_NAMES = {
    go_admin: "go-admin-token",
    go_write_teacher: "go-write-teacher-token",
    go_read_teacher: "go-read-teacher-token",
    go_write_pupil: "go-write-pupil-token",
    go_read_pupil: "go-read-pupil-token",
    go_portal_teacher: "go-portal-teacher-token",
    go_portal_pupil: "go-portal-pupil-token",
    go_parents_desktop: "go-parents-desktop-token",
    go_parents_mobile: "go-parents-mobile-token",
    boom_math_teacher: "boom-math-teacher-token",
    boom_math_pupil: "boom-math-pupil-token",
    boom_math_portal_teacher: "go-portal-teacher-token",
    boom_math_portal_pupil: "go-portal-pupil-token",
};

export const CHAPTER_STATUS = {
    teacher_chapter: -1,
    new: 0,
    writing_ready: 1,
    writing: 2,
    writing_closed: 3,
    voting_ready: 4,
    voting: 5,
    voting_closed: 6,
    completed: 7,
};

export const BOOK_STATUS = {
    new: 0,
    started: 1,
    completed: 2,
    printOrdered: 3,
    printDispatched: 4,
    printDelivered: 5,
    pdfGenerated: 6
};

export const READING_BOOK_STATUS = {
    new: 0,
    active: 1,
    complete: 2,
};

export const ENTRY_STATUS = {
    new: 1,
    submitted: 2,
    revisionRequested: 3,
};

export const BOOK_ORDER_STATUS = {
    pending: -1,
    new: 0,
    to_printer: 1,
    delivered: 2,
};

export const BOOK_ORDER_TYPE = {
    class: 0,
    parent: 1,
};

export const INVOICE_STATUS = {
    trial: -1,
    new: 0,
    issued: 1,
    paid: 2,
    partially_paid: 3,
};

export const SUBSCRIPTION_STATUS = {
    active: 1,
    expired: 2,
    cancelled: 9,
    cancelled_renewal: 10,
    trial_moved_to_paid: 99,
};

export const TEACHER_JOB_ROLES = {
    teacher: 1,
    teacher_assistant: 2,
    senior_leadership_team: 3,
    office: 4,
    english_lead: 5,
    volunteer: 6,
    tutor: 7,
    math_lead: 8,
    curriculum_specialist: 9,
};

export const YEAR_GROUPS = [
    {
        name: "Nursery",
        val: 1,
    },
    {
        name: "Reception",
        val: 2,
    },
    {
        name: "Year 1",
        val: 3,
    },
    {
        name: "Year 2",
        val: 4,
    },
    {
        name: "Year 3",
        val: 5,
    },
    {
        name: "Year 4",
        val: 6,
    },
    {
        name: "Year 5",
        val: 7,
    },
    {
        name: "Year 6",
        val: 8,
    },
    {
        name: "Year 7",
        val: 9,
    },
    {
        name: "Year 8",
        val: 10,
    },
    {
        name: "Year 9",
        val: 11,
    },
    {
        name: "Year 10",
        val: 12,
    },
    {
        name: "Year 11",
        val: 13,
    },
    {
        name: "Year 12",
        val: 14,
    },
    {
        name: "Year 13",
        val: 15,
    },
];

export const getAppTokenName = (app) => {
    switch (app) {
        case APPS.go_admin:
            return APP_TOKEN_NAMES.go_admin;
        case APPS.go_write_teacher:
            return APP_TOKEN_NAMES.go_write_teacher;
        case APPS.go_read_teacher:
            return APP_TOKEN_NAMES.go_read_teacher;
        case APPS.go_write_pupil:
            return APP_TOKEN_NAMES.go_write_pupil;
        case APPS.go_read_pupil:
            return APP_TOKEN_NAMES.go_read_pupil;
        case APPS.go_portal_teacher:
            return APP_TOKEN_NAMES.go_portal_teacher;
        case APPS.go_portal_pupil:
            return APP_TOKEN_NAMES.go_portal_pupil;
        case APPS.go_parents_desktop:
            return APP_TOKEN_NAMES.go_parents_desktop;
        case APPS.go_parents_mobile:
            return APP_TOKEN_NAMES.go_parents_mobile;
        case APPS.boom_math_teacher:
            return APP_TOKEN_NAMES.boom_math_teacher;
        case APPS.boom_math_pupil:
            return APP_TOKEN_NAMES.boom_math_pupil;
        case APPS.boom_math_portal_teacher:
            return APP_TOKEN_NAMES.boom_math_portal_teacher;
        case APPS.boom_math_portal_pupil:
            return APP_TOKEN_NAMES.boom_math_portal_pupil;
        default:
            return null;
    }
};

export const ENTRY_RATINGS_LABELS = [
    "", // LEAVE THIS BLANK ON PAIN OF DEATH!!!
    "Almost there",
    "OK",
    "Enjoyable",
    "Great",
    "Incredible!",
];

export const ENTRY_WORD_LIMIT = {
    danger: 2000,
    warning: 1970,
};

export const GOOGLE_SITE_KEY = "6LdTiKUZAAAAAH2LmjZQ-fVgaFB5hBdhLIZWpEfZ";

export const TERMS_VERSION = 2;
