import React, { Fragment, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import { BOOK_STATUS, CHAPTER_STATUS } from '../../../../../../services/utils/constants';
import { Button, Modal } from '@axeedge/go-teacher-components';
import moment from 'moment'
import EditGuidance from '../EditGuidance';
import ResourcesList from '../../../ResourcesList';

import {
    START_CHAPTER_MUTATION,
    START_WRITING_MUTATION,
    END_WRITING_MUTATION,
    REOPEN_WRITING_MUTATION,
    START_CASTING_SETUP_MUTATION,
    STOP_CASTING_MUTATION,
    REOPEN_CASTING_MUTATION,
    FINISH_CHAPTER_MUTATION,
    GET_CLASS_BOOK,
    AUTO_SET_WINNER_MUTATION,
    CHOOSE_WINNER_MUTATION,
    SET_BOOK_COMPLETED_MUTATION
} from '../../../../services/graphql';
import { updateChapter } from '../../../../services/utils';
import Tiebreak from '../Tiebreak';

import cx from 'classnames';
import styles from './ChapterSidebar.module.scss';
import { Printer, PlusCircle } from 'react-feather';

const INITIAL_REOPEN_MSG = 'Please check and read through your work carefully and making any edits before clicking hand-in.';

const ChapterSidebar = ({ chapter, enabled = true, nextChapter, setShowPrintableList, book }) => {
    const [tiebreak, setTiebreak] = useState(false);
    const [showReopenModal, setShowReopenModal] = useState(false);
    const [reopenMessage, setReopenMessage] = useState(INITIAL_REOPEN_MSG);
    const [showStartWritingModal, setShowStartWritingModal] = useState(false);

    const getButtonText = () => {
        switch(chapter.status) {
            case CHAPTER_STATUS.teacher_chapter:
            case CHAPTER_STATUS.completed:
                if (nextChapter) {
                    return `Start ${nextChapter.title}`;
                }
                return 'Publish Book';
            case CHAPTER_STATUS.writing_ready:
                return 'Start Writing';
            case CHAPTER_STATUS.writing:
                return 'End Writing';
            case CHAPTER_STATUS.writing_closed:
                return 'Start StarCasting';
            case CHAPTER_STATUS.voting_ready:
                return 'Begin!';
            case CHAPTER_STATUS.voting:
                return 'End StarCasting';
            case CHAPTER_STATUS.voting_closed:
                return 'Announce Winner';
            default:
                return '';
        }
    }

    const client = useApolloClient();

    const [startChapter] = useMutation(START_CHAPTER_MUTATION, {
        onCompleted: ({ startChapter }) => {
            updateChapter(startChapter, client);
        },
        refetchQueries: ({ query: GET_CLASS_BOOK, variables: { classBookId: chapter.classBook.id }})
    })

    const [startWriting, {loading: startingWriting}] = useMutation(START_WRITING_MUTATION, {
        variables: {
            bookChapterId: chapter.id
        },
        onCompleted: ({ startWritingForChapter }) => {
            updateChapter(startWritingForChapter, client);
            setShowStartWritingModal(false);
        }
    })

    const [endWriting] = useMutation(END_WRITING_MUTATION, {
        variables: {
            bookChapterId: chapter.id
        },
        onCompleted: ({ stopWritingForChapter }) => {
            updateChapter(stopWritingForChapter, client);
        }
    })

    const [reopenWriting] = useMutation(REOPEN_WRITING_MUTATION, { 
        onCompleted: ({ reopenWritingForChapter }) => {
            updateChapter(reopenWritingForChapter, client);
            setReopenMessage(INITIAL_REOPEN_MSG);
            setShowReopenModal(false);
        }
    })

    const [startCastingSetup] = useMutation(START_CASTING_SETUP_MUTATION, {
        variables: {
            bookChapterId: chapter.id
        },
        onCompleted: ({ startCastingSetupForChapter }) => {
            updateChapter(startCastingSetupForChapter, client);
        }
    })

    const [endCasting] = useMutation(STOP_CASTING_MUTATION, {
        variables: {
            bookChapterId: chapter.id
        },
        onCompleted: ({ stopCastingForChapter }) => {
            updateChapter(stopCastingForChapter, client);
        }
    })

    const [reopenCasting] = useMutation(REOPEN_CASTING_MUTATION, {
        variables: {
            bookChapterId: chapter.id
        },
        onCompleted: ({ reopenCastingForChapter }) => {
            updateChapter(reopenCastingForChapter, client);
        }
    })

    const [finishChapter] = useMutation(FINISH_CHAPTER_MUTATION, {
        variables: {
            bookChapterId: chapter.id
        },
        onCompleted: ({ finishChapter }) => {
            updateChapter(finishChapter, client);
        }
    })

    const [announceWinner] = useMutation(AUTO_SET_WINNER_MUTATION, {
        variables: {
            bookChapterId: chapter.id
        },
        onCompleted: ({ setChapterWinner }) => {
            if (setChapterWinner.tieBreaks && setChapterWinner.tieBreaks.length !== 0) {
                setTiebreak(setChapterWinner.tieBreaks);
                return;
            }
            if (setChapterWinner.errors && setChapterWinner.errors.length !== 0) {
                alert(setChapterWinner.errors[0]);
                return
            }
            finishChapter();
        }
    })

    const [chooseWinner] = useMutation(CHOOSE_WINNER_MUTATION, {
        onCompleted: ({ chooseChapterWinner }) => {
            if (chooseChapterWinner.errors && chooseChapterWinner.errors.length !== 0) {
                alert(chooseChapterWinner.errors[0]);
            } else {
                setTiebreak(false);
                finishChapter();
            }
        }
    })

    const [completeBook] = useMutation(SET_BOOK_COMPLETED_MUTATION, {
        variables: {
            classBookId: chapter.classBook.id
        },
        refetchQueries: ({ query: GET_CLASS_BOOK, variables: { classBookId: chapter.classBook.id }})
    })

    return (
        <Fragment>
            {showStartWritingModal && (
                <Modal closeModal={() => setShowStartWritingModal(false)}>
                    <h2>Are you sure?</h2>
                    <p className='u-m-base-2'>Starting the writing will make the project visible to your pupils.</p>
                    <Button disabled={startingWriting} onClick={() => startWriting()} className='u-m-right-2'>Start Writing</Button>
                    <Button onClick={() => setShowStartWritingModal(false)} outline>Cancel</Button>

                </Modal>
            )}
            {showReopenModal && (
                <Modal closeModal={() => setShowReopenModal(false)}>
                    <h1>Reopen Writing</h1>
                    <p className='u-m-base-2'>This will reopen the writing for all pupils. Please add guidance to support the writing as required.</p>
                    <div className='basic-form__group'>
                        <textarea
                            className='basic-form__text-area'
                            value={reopenMessage}
                            onChange={e => {
                                if (e.target.value.length === 0) {
                                    alert('Please enter guidance before reopening writing');
                                    return;
                                }
                                setReopenMessage(e.target.value);
                            }}
                        ></textarea>
                    </div>
                    <Button 
                        className='u-m-right-1'
                        onClick={() => {
                            reopenWriting(({ variables: { bookChapterId: chapter.id, teacherMessage: reopenMessage } }))
                        }}
                    >
                        Reopen Writing
                    </Button>
                    <Button
                        onClick={() => setShowReopenModal(false)}
                        outline    
                    >
                        Cancel
                    </Button>
                    
                </Modal>
            )}
            <div className={cx(styles.sidebar, 'hidden-print')}>
                <div className={cx(styles.sidebarContent)}>
                    
                    {
                        chapter && chapter.status !== CHAPTER_STATUS.new && (
                            <div className={styles.sidebarActions}>
                                {
                                    ((chapter.status === CHAPTER_STATUS.teacher_chapter && chapter.classBook.currentChapter === chapter.orderid) || (!chapter.classBook.currentChapter && !chapter.classBook.isSingleChapter)) && (
                                        <Fragment>
                                            <Button disabled={!enabled} fullWidth onClick={() => startChapter({ variables: { bookChapterId: nextChapter.id }})}>{getButtonText()}</Button>
                                        </Fragment>
                                    )
                                }
                                {chapter.status === CHAPTER_STATUS.writing_ready && <Button disabled={!enabled} onClick={() => setShowStartWritingModal(true)} fullWidth>{getButtonText()}</Button>}
                                {chapter.status === CHAPTER_STATUS.writing && (
                                    <Fragment>
                                        <Button onClick={() => endWriting()} fullWidth>{getButtonText()}</Button>
                                        {book.endsAt &&
                                            <div className="u-m-top-1 t-centered">
                                                <p>Scheduled to end:</p>
                                                <p className="heavy">{moment(new Date(book.endsAt)).format('MM/DD/YYYY')} @ {moment(new Date(book.endsAt)).format('h a')}</p>
                                            </div>
                                        }
                                    </Fragment>
                                )}
                                {
                                    chapter.status === CHAPTER_STATUS.writing_closed && chapter.classBook.castingEnabled && (
                                        <Fragment>
                                            <Button onClick={() => setShowReopenModal(true)} fullWidth outline className='u-m-base-2 u-m-right-1'>Re-open Writing</Button>
                                            <Button disabled={!enabled} onClick={() => startCastingSetup()} fullWidth>{getButtonText()}</Button>
                                            {!enabled && <p className='basic-form__hint u-p-top-2'>Must have at least 2 eligible entries to start StarCasting.</p>}
                                        </Fragment>
                                    )
                                }
                                {
                                    chapter.status === CHAPTER_STATUS.writing_closed && !chapter.classBook.castingEnabled && (
                                        <Fragment>
                                            <Button onClick={() => setShowReopenModal(true)} fullWidth outline className='u-m-base-2 u-m-right-1'>Re-open Writing</Button>
                                            <Button onClick={() => finishChapter()} fullWidth>Finish Activity</Button>
                                        </Fragment>
                                    )
                                }
                                {chapter.status === CHAPTER_STATUS.voting && <Button onClick={() => endCasting()} fullWidth>{getButtonText()}</Button>}
                                {
                                    chapter.status === CHAPTER_STATUS.voting_closed && (
                                        <Fragment>
                                            <Button onClick={() => reopenCasting()} fullWidth outline className='u-m-base-2 u-m-right-1'>Re-open StarCasting</Button>
                                            <Button onClick={() => announceWinner()} fullWidth>{getButtonText()}</Button>
                                        </Fragment>
                                    )
                                }

                                {
                                    chapter.status === CHAPTER_STATUS.completed && chapter.classBook.currentChapter === chapter.orderid && (
                                        nextChapter ? <Button className="u-m-base-2" fullWidth onClick={() => startChapter({ variables: { bookChapterId: nextChapter.id }})}>{getButtonText()}</Button> :
                                        chapter.classBook.status < BOOK_STATUS.completed ? <Button className="u-m-base-2" fullWidth onClick={() => completeBook()}>{getButtonText()}</Button> : null
                                    )

                                }
                                {
                                    chapter.status === CHAPTER_STATUS.completed && (
                                        <Fragment>
                                            {!chapter.classBook.castingEnabled && chapter.classBook.status < BOOK_STATUS.completed &&
                                                <Button onClick={() => setShowReopenModal(true)} fullWidth outline className='u-m-base-1 u-m-right-1'>Re-open Writing</Button>
                                            }
                                            <a 
                                                href={`/book/${chapter.classBook.id}?printStudentsChapter=${chapter.orderid}`}
                                                className='u-m-top-1 icon-title hidden-print'
                                                target='_blank'
                                                rel="noopener noreferrer"
                                            >
                                                <Button fullWidth outline className='icon-title'><Printer size='20' className='u-m-right-1' /> Print Entries</Button>
                                            </a>
                                        </Fragment>
                                    )
                                }
                            </div>
                        )
                    }

                    <ResourcesList book={book} setShowPrintableList={setShowPrintableList}/>

                    {/* {!chapter.classBook.classPack.isWritingActivity && chapter.status === CHAPTER_STATUS.writing && <EditGuidance chapter={chapter} />} */}

                    {chapter.status === CHAPTER_STATUS.writing && <EditGuidance chapter={chapter} />}

                    {
                        tiebreak && (
                            <Modal closeModal={() => setTiebreak(false)}>
                                <Tiebreak chooseWinner={chooseWinner} entries={tiebreak} />
                            </Modal>
                        )
                    }
                </div>

              
            </div>
        </Fragment>
    )
}

export default ChapterSidebar;
