import React from 'react';
import IsPromo from './scenes/IsPromo';
import IsPurchased from './scenes/IsPurchased';
import PrepareForDigitalPublishing from './components/PrepareForPrint/PrepareForDigitalPublishing';

const Completed = ({ book }) => {
    
    return (
        <div className='card card-mobile'>
            {book.bookTemplate.ispStarter? <PrepareForDigitalPublishing book={book}/> : 
            <p>Coming soon</p>}
            {/* {book.classPack?.isPromo && !book.classPack?.purchasedPack ? <IsPromo book={book} /> : <IsPurchased book={book} />} */}
        </div>
    )
}

export default Completed;
