import React, { Fragment, useEffect, useState, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { navigate } from '@reach/router';
import { AlertCircle, Eye, File, Trash2, XCircle } from 'react-feather';
import cx from 'classnames';
import { useApolloClient } from '@apollo/react-hooks';
import axios from 'axios';
import _ from 'lodash';

import { Button, Modal } from '@axeedge/go-teacher-components';

import { GET_S3_UPLOAD_QUERY } from '../services/graphql';
import gem from '../images/gem.svg';
import styles from '../Task.module.scss';
import { CHAPTER_STATUS } from '../../../services/utils/constants';

const TaskForm = ({ loading, task, onSubmitForm }) => {
    const [taskGuidance, setTaskGuidance] = useState(task.description);
    const [taskTitle, setTaskTitle] = useState(task.title);
    const [scEnabled, setScEnabled] = useState(task.castingEnabled);
    const [resources, setResources] = useState(task.resources);
    const [showSupportedModal, setShowSupportedModal] = useState(false);
    const [showScModal, setShowScModal] = useState(false);
    const [vocabs, setVocabs] = useState(task.vocabularyWords);

    const client = useApolloClient();

    const schema = Yup.object().shape({
        title: Yup.string()
            .required('Please enter a title')
    });

    const { register, handleSubmit, errors, setValue } = useForm({
        validationSchema: schema
    });

    useEffect(() => {
        setValue('guidance', taskGuidance);
    }, [setValue, taskGuidance]);

    useEffect(() => {
        setValue('title', taskTitle);
    }, [setValue, taskTitle]);

    const onSubmit = () => {
        const baseVariables = {
            studentsClassId: task.classId,
            title: taskTitle,
            description: taskGuidance,
            resources: resources.map(res => {
                return {
                    name: res.name,
                    url: res.url
                }
            }),
            castingEnabled: scEnabled,
            vocabularyWords: vocabs
        };

        onSubmitForm({
            variables: task.id ? { ...baseVariables, classBookId: task.id } : baseVariables
        });
    }

    const tagInput = useRef(null);

    const addVocabs = e => {
        const val = e.target.value;
        if ((e.key === 'Enter' || e.key === ',') && val && val.replace(/\s/g, '').length) {
            e.preventDefault();
            if (vocabs.find(word => word.replace(/\s/g, '').toLowerCase() === val.replace(/\s/g, '').toLowerCase())) {
                return;
            }
            setVocabs([...vocabs, val.trim()]);
            tagInput.current.value = null;
        }
    };

    const removeVocabs = i => {
        const newWords = [...vocabs];
        newWords.splice(i, 1);
        setVocabs(newWords);

    };

    useEffect(() => {
        setValue('vocabsInput', vocabs);
    }, [setValue, vocabs]);

    const cancelClicked = () => {
        if (task.id) {
            window.history.back();
        } else {
            navigate('/');
        }
    }

    const onSelectFile = files => {
        if (files.length) {
            const extension = files[0].name.split('.').pop().toLowerCase();
            const filename = `${task.id || ''}resource-${new Date().getTime()}.${extension}`;
            client.query({
                query: GET_S3_UPLOAD_QUERY,
                variables: {
                    name: filename,
                    fileType: extension,
                    object: 'resource'
                }
            }).then(r => {
                const options = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } }
                
                axios.put(r.data.s3UploadUrlFor.uploadUrl, files[0], options).then(s3r => {
                    const newResources = [...resources];
                    const newResouce = {
                        name: files[0].name,
                        url: r.data.s3UploadUrlFor.name
                    }
                    newResources.push(newResouce);
                    setResources(newResources);
                });
            })
        }
    }

    const shouldBeDisabled = () => {

        if (taskTitle === task.title && taskGuidance === task.description && scEnabled === task.castingEnabled && _.isEqual(_.sortBy(task.vocabularyWords), _.sortBy(vocabs)) && _.isEqual(_.sortBy(task.resources), _.sortBy(resources))) {
            return true;
        }
        return false;
    }

    const deleteResource = resId => {
        const newResources = [];
        resources.forEach(res => {
            if (res.id !== resId) {
                newResources.push(res);
            }
        });
        setResources(newResources);
    }

    return (
        <Fragment>
            <form className='card-mobile u-m-base-3' onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group basic-form__group--horizontal'>
                    <label className='basic-form__text-label basic-form__text-label heavy' htmlFor='visibleTitle'>Task Title</label>
                    <input 
                        className='basic-form__text-box' 
                        name='visibleTitle' 
                        placeholder='e.g. Happiness Poem' 
                        type='text' 
                        ref={register}
                        value={taskTitle}
                        onChange={e => setTaskTitle(e.target.value)}
                    />
                    <input type='hidden' name='title' ref={register} defaultValue={''} />
                </div>
                {errors.title && <p className='basic-form__hint'>{errors.title.message}</p>}
                
                <div className='basic-form__group basic-form__group--horizontal u-m-top-1'>
                    <label className='basic-form__text-label heavy' htmlFor='visibleGuidance'>Pupil Instruction/Guidance (Optional)</label>
                    <TextareaAutosize
                        name='visibleGuidance'
                        onChange={e => setTaskGuidance(e.target.value)}
                        value={taskGuidance}
                        placeholder='e.g. Write a poem on what happiness means to you. Try to use the descriptive words from our lesson.'
                        className='basic-form__text-box'
                    />
                    <input type='hidden' name='guidance' ref={register} defaultValue={''} />
                </div>
                {errors.guidance && <p className='basic-form__hint'>{errors.guidance.message}</p>}
                {task.status && task.status >= CHAPTER_STATUS.writing_closed ?
                    <>
                        {!!resources.length && resources.map(res => {
                            return (
                                <a href={res.url} rel="noopener noreferrer" target='_blank' key={res.id || res.name} className={styles.taskFile}>
                                    <File />
                                    <p className={styles.taskFileName}>{res.name}</p>
                                    {res.url.includes('amazon') && <span className={styles.taskFileView}><Eye /></span>}
                                </a>
                            )})
                        }
                    </>
                    : <>
                        <div className='basic-form__group basic-form__group--horizontal u-m-top-3'>
                            <p className='basic-form__text-label heavy'>Upload Supporting Files</p>
                            <p className='u-m-base-1'>Pupils will be able to view and download these while writing. <span className='u-link u-link--green' onClick={() => setShowSupportedModal(true)}>See supported file types</span>.</p>
                            {!!resources.length && resources.map(res => {
                                return (
                                    <div key={res.id || res.name} className={styles.taskFile}>
                                        <File />
                                        <p className={styles.taskFileName}>{res.name}</p>
                                        {res.url.includes('amazon') && <a className={styles.taskFileView} href={res.url} rel="noopener noreferrer" target='_blank'><Eye /></a>}
                                        <Trash2 onClick={() => deleteResource(res.id)} className={styles.taskFileDelete} />
                                    </div>
                                )})
                            }
                            <label htmlFor='FileUpload' className={cx(styles.taskFileUploadLabel, 'u-m-right-2')}>Select File<File className={styles.taskFileUploadIcon}/></label>
                            <input 
                                id='FileUpload' 
                                type='file' 
                                accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'
                                onChange={e => onSelectFile(e.target.files)} 
                                className={cx('u-m-base-2', styles.taskFileUploadForm)} 
                            />
                            {/* <button type='button' className={styles.taskUploadButton}>Select Files <File /></button> */}
                        </div>

                        <div className='basic-form__group--horizontal'>
                            <label className='basic-form__text-label basic-form__text-label--slim' htmlFor='word'>Word bank</label>
                            <input
                                type="text"
                                placeholder='Separate words with comma or enter'
                                className='basic-form__text-box'
                                onKeyPress={(e) => addVocabs(e)}
                                ref={tagInput}
                            />
                        </div>
                        <div className={styles.vocabField}>
                            {vocabs.map((vocab, i) => {
                                return (
                                    <span className={styles.vocabItem} key={`vocab${i}`}>
                                        {vocab}
                                        <button className={styles.vocabItemDelete} type="button" onClick={() => removeVocabs(i)}><XCircle size="18" /></button>
                                    </span>
                                )
                            })}
                            <input type='hidden' name='vocabsInput' ref={register} defaultValue={task.vocabularyWords.join(',')} />
                        </div>
                        
                        <div className='basic-form__group u-m-top-3'>
                            <label className='basic-form__text-label basic-form__text-label--auto heavy'>Enable StarCasting</label>
                            <input disabled={task.status && task.status >= CHAPTER_STATUS.voting} className='switch' type='checkbox' id='switch' checked={scEnabled} onChange={(e) => setScEnabled(!scEnabled)} value={scEnabled}/>
                            <label className='switchLabel' htmlFor='switch'>Enable StarCasting</label>
                            <AlertCircle className='u-link u-m-left-2' onClick={() => setShowScModal(true)} />
                        </div>
                        <div className='u-display-flex'>
                            <img src={gem} alt='Gem' className='u-m-right-1' />
                            <p>{scEnabled ? 'Pupils will be awarded Gems for Writing and Star Casting.' : 'Pupils will be awarded Gems for Writing.'}</p>
                        </div>
                    </>
                }
                <div className='basic-form__group basic-form__group--horizontal u-m-top-3 u-m-base-2'>
                    <p className='basic-form__text-label heavy'>Save Activity to Continue</p>
                    <p>Pupils won’t see this activity until you start the writing!</p>
                </div>
                
                <Button 
                    type='submit' 
                    className='u-m-right-2' 
                    primary 
                    disabled={loading || taskTitle === '' || shouldBeDisabled()}
                >
                    {loading ? 'Saving...' : `Save${task.id ? ' Changes' : ''}`}
                </Button>
                <Button outline onClick={() => cancelClicked()} type='button'>Cancel</Button>
            </form>
            {
                showSupportedModal && (
                    <Modal closeModal={() => setShowSupportedModal(false)}>
                        <h1>Supported Files</h1>
                        <p>You can upload files in the following formats:</p>   
                        <p className="heavy u-m-top-2 u-m-base-2">pdf, Word and Office docs (.docx, .pptx etc), Image files (jpg, png, giff)</p>
                        <p>Files must not exceed 5Mb.</p>
                    </Modal>
                )
            }
            {
                showScModal && (
                    <Modal closeModal={() => setShowScModal(false)}>
                        <p className="u-m-base-2">Starcasting is the name we give to our peer review system. You have full control over which entries will be included (pupils will not know if you chose to exclude their work).</p>
                        <p>Pupils are shown a single anonymised entry at a time and asked to give the piece a star rating based on how well they liked it. Pupils can review multiple pieces. The class winner is the entry with the highest average star rating.</p>
                    </Modal>
                )
            }
        </Fragment>
    )
}

export default TaskForm;