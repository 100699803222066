import { gql } from "apollo-boost";

export const GET_TEACHER_BOOKS = gql`
query currentTeacher {
    currentTeacher {
        id
        classBooks {
            id
            status
            createdAt
            completedAt
            bookTemplate {
                id
                name
            }
            studentsClass {
                id
                name
            }
        }
    }
}
`;