import React, { useState } from 'react';
import { Book, XCircle, Settings } from 'react-feather';
import cx from 'classnames';

import { BOOK_STATUS } from '../../../../services/utils/constants';

import styles from './BookSidebar.module.scss';
import ImageFadeIn from 'react-image-fade-in';
import logo from '../../../../images/logos/go-write-icon.svg';
import noCover from '../../../../images/no-cover.png';

const BookSidebar = ({ book, setSelectedChapter, selectedChapter }) => {

    const [mobileMenu, setMobileMenu] = useState(false);

    const toggleMenu = () => {
        mobileMenu ? setMobileMenu(false) : setMobileMenu(true);
    }

    const closeMenu = () => {
        setMobileMenu(false) ;
    }


    return (
        <div className={styles.bookMenu}>
            <div onClick={toggleMenu} className={styles.bookMenuBrand}>
                <span className={styles.bookMenuBrandToggle}></span>
                <ImageFadeIn src={logo} alt='Go Apps Logo' />
            </div>

            <div className={styles.bookMenuCover}>
                <ImageFadeIn src={book.bookTemplate.coverUrl || noCover} alt={`Cover for ${book.bookTemplate.name}`} />
            </div>

            <div className={cx(styles.bookMenuContent, {[styles.bookMenuMobileShown]: mobileMenu})}>
                <div className={styles.bookMenuInner}>
                    <ul>
                        <li
                            className={styles.chapter}
                            onClick={() => {
                                setSelectedChapter(-2);
                                closeMenu();
                            }}
                        >
                            <p className={cx(styles.title, {[styles.titleSelected]: selectedChapter === -2})}><Settings /></p>
                        </li>
                        {
                            book.chapters.map(chapter => {
                                return (
                                    <li
                                        key={`chapter_${chapter.id}`}
                                        className={styles.chapter}
                                        onClick={() => {
                                            setSelectedChapter(chapter.orderid);
                                            closeMenu();
                                        }}
                                    >
                                        <p className={cx(styles.title, {[styles.titleSelected]: selectedChapter && (chapter.id === selectedChapter.id)})}>{chapter.orderid + 1}</p>
                                    </li>
                                )
                            })
                        }
                        <li
                            key='chapter_complete'
                            className={cx(styles.chapter, {[styles.chapterDisabled]: book.status < BOOK_STATUS.completed})}
                            onClick={() => setSelectedChapter(-1)}
                        >
                            <p className={cx(styles.title, {[styles.titleSelected]: selectedChapter === -1})}><Book /></p>
                        </li>
                    </ul>

                    <div className={styles.bookMenuMobileExtra}>
                        <div className={styles.bookMenuMobileExtraTop}>
                            <ImageFadeIn className={styles.bookMenuMobileExtraImg} src={book.bookTemplate.coverUrl || noCover} alt={`Cover for ${book.bookTemplate.name}`} />
                            <span className={styles.bookMenuMobileExtraClose}><XCircle size='35' onClick={closeMenu} /></span>
                        </div>
                        <h1>{book.title || book.bookTemplate.name}</h1>
                        <h3>{selectedChapter === -1 && selectedChapter ? '' : selectedChapter.title}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookSidebar;
