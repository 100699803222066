import React, { useState } from 'react';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';
import WriteItem from '../Writes/components/WriteItem';
import { Users } from 'react-feather';
import { useQuery } from '@apollo/react-hooks';
import { GET_TEACHER_BOOKS } from './services/graphql';
import { BOOK_STATUS } from '../../services/utils/constants';
import { PlusCircle, ChevronDown, ChevronUp, Search } from "react-feather";
import { Link } from "@reach/router";
import styles from '../Writes/Writes.module.scss';

const NEW_TO_OLD = 'Date New To Old';
const OLD_TO_NEW = 'Date Old To New';

const NewDashboard = () => {

    const { data, loading, error } = useQuery(GET_TEACHER_BOOKS, {
        fetchPolicy: 'network-only'
    })


    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState(NEW_TO_OLD);
    const [showCompleted, setShowCompleted] = useState(false);

    if (loading) return <p>Loading...</p>
    if (error) return <p>{error.message}</p>


    if (data?.currentTeacher) {
        const classBooks = data?.currentTeacher?.classBooks || [];
        const completedBooks = classBooks.filter(b => b?.status >= BOOK_STATUS.completed);
        const visibleBooks = searchTerm === '' ? completedBooks : completedBooks.filter(b => b.bookTemplate?.name?.toLowerCase().includes(searchTerm))
        const sortedBooks = sortOrder === NEW_TO_OLD ? [...visibleBooks].sort((a, b) => b.completedAt.localeCompare(a.completedAt)) : [...visibleBooks].sort((a, b) => a.completedAt.localeCompare(b.completedAt))

        return (
            <>

                <div className='page-header'>
                    <div className='page-header__content'>
                        <div className='page-header__content__inner'>
                            <h2 className='page-header__content__title'><Users /> Select a write </h2>
                        </div>
                    </div>
                </div>
                <div className='card card-mobile u-m-base-2'>
                    <Tabs>
                        <Tab selected={!showCompleted} onClick={() => setShowCompleted(false)}>Current writes</Tab>
                        <Tab selected={showCompleted} onClick={() => setShowCompleted(true)}>Completed writes</Tab>
                    </Tabs>
                </div>

                <div className={styles.writesWrapper}>
                    {!showCompleted ?
                        <div className='row'>
                            {classBooks.map(book => {
                                if (book.status >= BOOK_STATUS.completed) {
                                    return null
                                }
                                return <WriteItem key={book.id} book={book} />
                            })}
                            <Link to='/startWrite' className={`${styles.addTaskBtn} col-lg-4 col-md-6`}>
                                <div className='card card-hover'>
                                    <div className={styles.addTaskBtnContent}>
                                        <PlusCircle className={styles.addTaskBtnIcon} />New Writing
                                    </div>
                                </div>
                            </Link>
                        </div>
                        :
                        <>
                            <div className='row u-m-base-2'>
                                <div className="col-md-4">
                                    <div className={styles.utilitiesSearch}>
                                        <input className={`${styles.utilitiesBtn} basic-form__text-box u-align-center`} type='search' placeholder='Search Activities' onChange={e => setSearchTerm(e.target.value)} value={searchTerm} />
                                        <Search size={20} className={styles.utilitiesSearchIcon} />
                                    </div>
                                </div>
                              <div className="col-md-4">
                                    <button className={`${styles.utilitiesBtn} basic-form__text-box u-align-center`} onClick={() => setSortOrder(sortOrder === NEW_TO_OLD ? OLD_TO_NEW : NEW_TO_OLD)}>
                                        <span className='u-m-right-1'>{sortOrder}</span>{sortOrder === NEW_TO_OLD ? <ChevronDown color='#bbb' /> : <ChevronUp color='#bbb' />}
                                    </button>
                              </div>
                            </div>
                            <div className='row'>
                                {sortedBooks.length === 0 ?
                                    <>
                                        {searchTerm !== '' ? 'No writes match your search criteria' : 'No completed writes'}
                                    </> :
                                    sortedBooks.map(book => <WriteItem key={book.id} book={book} />)
                                }
                            </div>
                        </>
                    }

                    {!showCompleted &&
                        <div className={styles.writesFooter}>
                            <Link to='/startWrite' className="btn btn-primary">+ Create new</Link>
                        </div>
                    }
                </div>
            </>
        )
    }
    return null;
}

export default NewDashboard;