import React, { useCallback, useRef, useState } from 'react';
import EditorJs from 'react-editor-js';
import { Button, Modal, FormErrors } from '@axeedge/go-teacher-components';

import { useMutation } from '@apollo/react-hooks';

import { CheckSquare, Square, AlertCircle } from 'react-feather';
import cx from 'classnames';
import styles from './PrepareForPrint.module.scss';
import DigitalBooks from '../../scenes/DigitalBooks';
import { GET_CLASS_BOOK, GENERATE_CLASS_BOOK_PDFS, EDIT_CLASS_BOOK } from '../../../../services/graphql';
import { BOOK_STATUS } from '../../../../../../services/utils/constants';

const PrepareForDigitalPublishing = ({ book }) => {

    const [confirmPrint, setConfirmPrint] = useState();

    const [review, setReview] = useState({
        names: false,
        allEdits: false,
        happy: false,
        winnerEdit: false
    });

    const [formBookErrors, setFormBookErrors] = useState([]);
    const [formErrors, setFormErrors] = useState([]);


    const [generateClassBookPdfs, { loading: publishing }] = useMutation(GENERATE_CLASS_BOOK_PDFS, {
        onCompleted: ({ generateClassBookPdfs }) => {
            setConfirmPrint(false);
            if (generateClassBookPdfs?.errors?.length > 0) {
                setFormErrors(generateClassBookPdfs.errors);
                return;
            }
        },
        refetchQueries: ({ query: GET_CLASS_BOOK, variables: { classBookId: book.id } })
    });

    const editorJsRef = useRef(null);

    const [saveSettings, { loading: savingForward }] = useMutation(EDIT_CLASS_BOOK, {
        onCompleted: ({ editClassBook }) => {
            if (editClassBook.errors && editClassBook.errors.length !== 0) {
                setFormBookErrors(editClassBook.errors);
                return;
            }
        }
    });

    const onSaveEdit = useCallback(async () => {
        const savedData = await editorJsRef.current.save();
        if (savedData.blocks.length !== 0) {
            saveSettings({
                variables: {
                    classBookId: book.id,
                    foreword: JSON.stringify(savedData)
                }
            })
        }
    }, [saveSettings, book.id])

    const onPublishOrder = () => {
        generateClassBookPdfs({
            variables: {
                classBookId: book.id
            }
        });
    }

    if (book.status === BOOK_STATUS.pdfGenerated) {
        return <DigitalBooks book={book} />
    }

    return (
        <div className='row'>
            <div className="col-md-8 u-m-base-3">
                <h2 className='u-m-base-3 heavy'>Publish this digital book</h2>

                <div className={styles.introAlert}>
                    <AlertCircle size="30" />
                    <p className='heavy'>Completed books will be available to download as a PDF</p>
                </div>

                <h2 className='heavy'>Foreword</h2>
                <p>Please complete a book foreword which will appear in the digital book</p>
                <div className='card-muted u-m-base-3'>
                    <EditorJs
                        data={JSON.parse(book.foreword)}
                        placeholder='Book foreword (required)'
                        instanceRef={instance => (editorJsRef.current = instance)}
                        enableReInitialize={false}
                        minHeight='0'
                        logLevel='WARN'
                    />
                    <Button className='u-m-top-2' outline disabled={savingForward} onClick={() => onSaveEdit()}>{savingForward ? 'Saving...' : 'Save'} </Button>
                    {formBookErrors.length !== 0 && <FormErrors errors={formBookErrors} />}
                </div>

                <p className='u-m-base-2'>Please take a few moments to review the checklist and when you are happy we will prepare the final versions</p>

                <div className={cx(styles.completedConfirmActions, 'u-m-base-3 u-m-top-1')}>
                    <div className='u-m-top-3'>
                        <div onClick={() => setReview({ ...review, names: !review.names })} className={styles.completedCheck}>
                            {review.names ? <CheckSquare /> : <Square />} <p>Student names are spelt correctly (if required)</p>
                        </div>
                        {
                            !book.isSingleChapter && (
                                <div onClick={() => setReview({ ...review, winnerEdit: !review.winnerEdit })} className={styles.completedCheck}>
                                    {review.winnerEdit ? <CheckSquare /> : <Square />} <p>Winning chapters edited</p>
                                </div>
                            )
                        }
                        <div onClick={() => setReview({ ...review, allEdits: !review.allEdits })} className={styles.completedCheck}>
                            {review.allEdits ? <CheckSquare /> : <Square />} <p>{book.isSingleChapter ? 'All desired edits to all entries made' : 'All desired edits to last chapter made'}</p>
                        </div>

                        <div onClick={() => setReview({ ...review, happy: !review.happy })} className={styles.completedCheck}>
                            {review.happy ? <CheckSquare /> : <Square />} <p>I'm happy for my book to be digitally published</p>
                        </div>

                    </div>
                </div>
                <Button
                    onClick={() => setConfirmPrint(true)}
                    disabled={!(review.names && review.allEdits && review.happy && review.winnerEdit)}
                >
                    Publish
                </Button>
            </div>


            {
                confirmPrint && (
                    <Modal closeModal={() => setConfirmPrint(false)}>
                        <div className='u-text-center'>
                            <h3 className='u-m-base-3'>Are you sure? You can make no changes to the book after clicking to confirm.</h3>
                            <Button disabled={publishing} onClick={() => onPublishOrder()} className='u-m-right-2'>{publishing ? 'Please wait...' : 'Yes'}</Button>
                            <Button outline onClick={() => setConfirmPrint(false)}>No</Button>
                            {formErrors && <FormErrors errors={formErrors} />}
                        </div>
                    </Modal>
                )
            }
        </div>
    )
}

export default PrepareForDigitalPublishing;